// eslint-disable-next-line @typescript-eslint/no-unused-vars
import axios from 'axios'
import { mainAxiosToken, mainAxios } from 'libs/axios'

interface dataProps {
  addr?: any
  ref?: any
}

interface dataRegister {
  addr?: any
}

// eslint-disable-next-line import/prefer-default-export
export const GetProfile = async (params?: dataProps) => {
  return mainAxios
    .get('/api/v1/public/user/profile', {params})
    .then((response) => {
      // console.log("response", response);
      return response
    })
    .catch((error) => {
      console.log(`error.response`, error.response)
    })
}

// eslint-disable-next-line import/prefer-default-export
export const InviteMember = async (params?: dataProps) => {
  return mainAxios
    .post('/api/v1/public/user-invite/invite', params)
    .then((response) => {
      // console.log("response", response);
      return response
    })
    .catch((error) => {
      console.log(`error.response`, error.response)
      return error.response
    })
}

export const InviteRegister = async (params?: dataRegister) => {
  return mainAxios
    .post('/api/v1/public/user-invite/register', params)
    .then((response) => {
      // console.log("response", response);
      return response
    })
    .catch((error) => {
      console.log(`error.response`, error.response)
    })
}


export const InviteList = async (params?: dataProps) => {
  return mainAxios
    .post('/api/v1/public/user-invite/list', params)
    .then((response) => {
      // console.log("response", response);
      return response
    })
    .catch((error) => {
      console.log(`error.response`, error.response)
    })
}

interface dataStaking {
  addr?: string
  amount?: any
  packageType?: any

}
export const PackageStaking = async (params?: dataStaking) => {
  return mainAxios
    .post('/api/v1/public/user-package/staking', params)
    .then((response) => {
      // console.log("response", response);
      return response
    })
    .catch((error) => {
      console.log(`error.response`, error.response)
    })
}
