import React from 'react'

/** Style */
import { Content, Wrapper, } from './style'


const MixLayout: React.FC = ({ children }) => {

  return (
    <Wrapper>
      <Content>
        {children}
      </Content>
    </Wrapper>
  )
}

export default (MixLayout)
