import { useCallback } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { NoBscProviderError } from '@binance-chain/bsc-connector'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector'

/** Store */
import { fetchInDicator } from 'state/actions'
import { useAppDispatch } from 'state'

/** Name */
import { connectorsByName } from 'utils/web3React'
import { setupNetwork } from 'utils/wallet'

export enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
  BSC = 'bsc',
  KAIKAS = 'Kaikas',
  DECENT = 'Decent',
  KLIP = 'Klip',
}

const useAuth = () => {
  const { activate, deactivate } = useWeb3React()
  const dispatch = useAppDispatch()
  // const location = useLocation();
  // console.log('location', location);
  /** Show loading */
  const showIndicator = () => {
    dispatch(
      fetchInDicator({
        isShow: true,
      }),
    )
  }

  /** Hide loading */
  const hideIndicator = () => {
    dispatch(
      fetchInDicator({
        isShow: false,
      }),
    )
  }
  const logout = () => {
    deactivate();
    // dispatch(setDataUserCreate({ account: '' }))
  }


  // const { toastError } = useToast()
  const login = useCallback((connectorID: ConnectorNames) => {
    const connector = connectorsByName[connectorID]
      if (connector) {
      activate(connector, async (error: Error) => {
        if (error instanceof UnsupportedChainIdError) {

          const hasSetup = await setupNetwork()
          
          if (hasSetup) {
            activate(connector)
            hideIndicator()
          }
        } else {
          // window.localStorage.removeItem(connectorLocalStorageKey)
          // eslint-disable-next-line no-lonely-if
          if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {

            showIndicator()
          } else if (
            error instanceof UserRejectedRequestErrorInjected ||
            error instanceof UserRejectedRequestErrorWalletConnect
          ) {
            if (connector instanceof WalletConnectConnector) {
              const walletConnector = connector as WalletConnectConnector
              walletConnector.walletConnectProvider = null
            }
            // toastError('Authorization Error', 'Please authorize to access your account')
          } else {
            // toastError(error.name, error.message)
          }
        }
      })
    } else {
      // toastError("Can't find connector", 'The connector config is wrong')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { login, logout }
}

export default useAuth
