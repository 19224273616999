/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

// import { DataGasLimitState } from '../types'

const initialState: any = { 
  data: { isPopup: false, f1: '' }, 
  popup: '', 
  popup2: '', 
  isConfirm: false,
  onConfirm: null 
}

export const popupSlice = createSlice({
  name: 'DataPopup',
  initialState,
  reducers: {
    setDataPopup: (state, action?: any) => {
      // return { ...state, data: action.payload}
      state.data = action.payload
    },
    setContentPopup(state: any, action) {
      state.popup = action.payload
      state.isConfirm = false
    },
    setConfirmPopup(state: any, ) {
      state.popup = ''
      state.isConfirm = true
    },
    setContentPopup2(state: any, action) {
      state.popup2 = action.payload
      state.isConfirm = false
    },
  },
})

// Actions
export const { setDataPopup,setContentPopup, setConfirmPopup,setContentPopup2 } = popupSlice.actions

// Thunks
export const fetchPopup = (data) => async (dispatch?: any) => {
  dispatch(setDataPopup(data))
}

export default popupSlice.reducer
