import styled from 'styled-components'
import { Button as ButtonCustom } from 'components/Button'

type Props = {
  isNavActive?: boolean
}

export const Wrapper = styled.div<Props>`
  width: 100%;
  max-width: 100%;
  /* height: 64px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* position: ${(p) => (p.isNavActive ? 'fixed' : 'static')}; */
  position: fixed;
  /* background-color: #5b50ea; */
  z-index: 99;
  top: 0;
  &.scroll{
    backdrop-filter: blur(16px);
  }
  @media (max-width: 767px) {
    /* height: 60px; */
  }
  p {
    /* font-family: Poppins-Medium;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin: 0; */
  }
  .menu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    @media (max-width: 1025px) {
      p,
      > a {
        display: none;
      }
    }
  }
`
export const WraperUseWallet = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const WalletButton = styled(ButtonCustom)`
  /* height: 40px;
  border-radius: 4px;
  position: absolute;
  right: 10px;
  font-size: 12px;
  padding: 0px 10px; */
  /* height: 40px; */
  min-width: 125px;
  object-fit: contain;
  border-radius: 4px;
  border: solid 2px #375bd2;
  background-color: transparent;
  /* font-family: Poppins-Medium; */
  font-size: 16px;
  text-align: center;
  color: #375bd2;
  padding: 0;
  transition: background-color .3s, border-color .3s, color .3s;
  span {
    font-size: 16px;
    margin-left: 8px;
  }
  @media (max-width: 767px) {
    font-size: 12px;
    padding: 0px 5px;
    min-width: 100px;
  }
`
export const Content = styled.div`
  max-width: 1600px;
  margin: auto;
  height: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  z-index: 99;
  position: relative;
  padding: 0 20px;
  height: 100px;
  @media (max-width: 1199px) {
    padding: 0 20px;
  }
`

export const Notice = styled.div`
  color: #ff4d4d;
`

export const Button = styled.button`
  min-width: 125px;
  height: 40px;
  margin: 0 0 0 20px;
  object-fit: contain;
  border-radius: 6px;
  background-image: linear-gradient(152deg, #119bd4 -89%, #7c44c0 51%, #da709d 113%);
  font-family: Poppins-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: none;
  cursor: pointer;
  /* @media (max-width: 600px) {
    display: none;
  } */
  @media (max-width: 767px) {
    font-size: 12px;
    padding: 0px 5px;
    min-width: 80px;
  }
`

export const ButtonOutline = styled.button`
  /* width: 104px; */
  height: 40px;
  object-fit: contain;
  border-radius: 6px;
  border: solid 1px #fff;
  background-color: #04002d;
  font-family: Poppins-Medium;
  font-size: 16px;
  line-height: 1.88;
  text-align: center;
  color: #fff;
  @media (max-width: 600px) {
    font-size: 14px;
    /* width: 84px; */
    height: 32px;
  }
`

export const ImgLogo = styled.img`
  max-height: 81px;
  /* height: 58px; */
  /* &.center {
    margin: auto;
  }
  @media (max-width: 767px) {
    width: 200px;
    height: auto;
  }
  @media (max-width: 540px) {
    width: 150px;
    height: auto;
  } */
`

export const MenuMobile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 40px;
  svg,img {
    width: 30px;
    /* height: 32px; */
  }
  @media (min-width: 1026px) {
    display: none;
  }
  @media (max-width: 600px) {
    margin-left: 25px;
    svg,img {
      width: 20px;
      /* height: 32px; */
    }
  }
`
export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  /* margin-left: auto; */
`
export const WrapperMenu = styled.div`
  right: -100%;
  background: rgba(16, 12, 67, 0.87);
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 10;
  /* top: 43px; */
  /* transform: translateY(17px); */
  display: flex;
  flex-direction: column;
  gap: 25px 0;
  padding-top: 100px;
  transition: all 0.6s ease-in-out;
  /* left: 0; */
  &.active{
    right: 0;
  }
  @media (max-width: 600px) {
    height: 100vh;
    gap: 20px 0;
    overflow-y: auto;
    /* padding-top: 100px; */
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    /* padding-top: 100px; */
  }
  p {
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    margin: 0;
    font-family: Poppins-Medium;
    @media (max-width: 600px) {
      font-size: 18px;
      line-height: 1.67;
    }
  }
  & > p {
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    display: none;
  }
  
`

export const WrapperItem = styled.p`
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #dedede;
  margin: 0;
  margin-right: 30px;
  font-family: Poppins-Medium;
  cursor: pointer;

  @media (max-width: 600px) {
    font-size: 18px;
    line-height: 1.67;
  }
`

export const WrapperLinkItem = styled.a`
  line-height: 1.45;
  letter-spacing: 0.11px;
  padding: 20px 10px;
  font-size: 1.5rem;
  font-family: 'Poppins-Regular';
  margin: 0;
  text-decoration: unset;
  cursor: pointer;
  color: #fff;
  text-align: center;
  &:hover {
    color: #324865;
    .list-item {
      display: block;
    }
  }
  &.home{
    color: #3d4556;
  }
  @media (min-width: 1024px) {
    padding: 20px 30px;
    /* font-size: 1.5rem; */
  }
`

export const ImgLink = styled.img`
  cursor: pointer;
`

export const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 0 100px;
  gap: 0 40px;
`
