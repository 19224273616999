/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { GetProfile } from 'apis/InviteMember'
// import getCaver from 'kaikas'

// import { DataGasLimitState } from '../types'

const initialState: any = {
  data: [],
  bonus: 0,
  bonus_debt: 0,
  balance: 0
}

export const userCreateSlice = createSlice({
  name: 'DataUserCreate',
  initialState,
  reducers: {
    setDataUserCreate: (state, action) => {
      // return { ...state, data: action.payload}
      state.data = { ...state.data, ...action.payload }
    },
    setUser(state, { payload }: any) {
      const { bonus, bonus_debt,balance } = payload
      state.bonus = bonus
      state.bonus_debt = bonus_debt
      state.balance = balance
    },
  },
})

// Actions
export const { setDataUserCreate, setUser } = userCreateSlice.actions

// Thunks
export const fetchCreateUser = (address?: any) => async (dispatch?: any) => {
  if (address) {
    dispatch(setDataUserCreate({ account: address }))
  } else {
    // let result = ['']
    // try {
    //   result = await getCaver()
    // } catch (error) {
    //   result = ['']
    // }
    const account = ''

    dispatch(setDataUserCreate({ account }))
  }
}

export const fetchProfile =
  // eslint-disable-next-line @typescript-eslint/ban-types
  (address) => async (dispatch) => {
    if (address) {
      // dispatch(setDataUserCreate({ account: address }))
      const response: any = await GetProfile({ addr: address })
      const data = response.data?.data
      console.log('response ddddddddddd :>> ', data)
      dispatch(setUser({ ...data }))
    }
  }

export default userCreateSlice.reducer
