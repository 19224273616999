import React from 'react';//lazy
import * as S from './style';

interface Props {
  visible?: boolean;
  children?: any;
  onCancel?: any;
  onOk?: any;
  title?: any;
  footer?: boolean;
  okText?: string;
  cancelText?: string;
  cancel?: boolean;
  okCancel?: boolean;
  isCloseBtn?: boolean;
  className?: any;
  path?: string;
  width?: string;
}

function Popup({
  className,
  visible,
  children,
  onCancel,
  cancel = true,
  okCancel = true,
  title,
  onOk,
  okText = 'Confirm', 
  cancelText = 'Cancel',
  isCloseBtn = true,
  path,
  width,
}: Props): React.ReactElement {
  return (
    <S.ComponentWp
      title={title}
      centered
      visible={visible}
      onCancel={onCancel}
      mask={className === 'hidden'}
      className={`${className} popup-container ${okCancel ? "" : "ok-cancel"} ${
        cancel ? "" : "not-cancel"
      } ${isCloseBtn ? "" : "not-close-btn"}`}
      onOk={onOk}
      okText={okText}
      cancelText={cancelText}
      path={path}
      width={width}
    >
      {children}
    </S.ComponentWp>
  );
}

export default Popup;
