import React, { lazy, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { Router, Route, Redirect, useLocation } from 'react-router-dom'
import { Menu } from 'components/Menu'
import { useWeb3React } from '@web3-react/core'
import useAuth from 'hooks/useAuth'
// eslint-disable-next-line import/no-unresolved
import { useAppDispatch } from 'state'

import useEagerConnect from 'hooks/useEagerConnect'
import { useFetchPublicData } from 'state/hooks'
// import Loading from 'components/LoadingNew'
// import Popup from 'components/Popup'

import { fetchInviteMember, fetchInviteRegister } from 'state/actions'
import { InviteMember, InviteRegister } from 'apis'

/** Page */
// import HomePage from 'views/Home'
import MixLayout from 'views/MixLayout'
// import StakeComponent from 'views/Stake'
// import BuyTicket from 'views/BuyTicket'
import NotFound from 'views/NotFound'
import Notice from 'components/Notice'
import Notice2 from 'components/Notice2'
import { setContentPopup, setContentPopup2 } from 'state/Popup'
import Loading from 'components/Loading'

import history from './routerHistory'
import GlobalStyle from './style/Global'

const StakeComponent = lazy(() => import('views/Stake'))
const InviteComponent = lazy(() => import('views/InvitedUsers'))
const Introduce = lazy(() => import('views/Introduce'))
const Profile = lazy(() => import('views/Profile'))
const CommissionPolicy = lazy(() => import('views/CommissionPolicy'))

declare global {
  interface Window {
    ethereum?: any
  }
}

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
  FORMAT: {
    groupSize: 3,
    groupSeparator: ',',
    decimalSeparator: ',',
  },
})

const App: React.FC = () => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const dispatch = useAppDispatch()
  const location = useLocation()

  useEagerConnect()
  // useFetchPublicData()
  const errorr = false

  const apiInviteRegister = async () => {
    const response: any = await InviteRegister({ addr: account })
    console.log('response :>> ', response)
  }
   const apiInviteMember = async (data) => {
    const response: any = await InviteMember(data)
    if(response?.status !== 200){
      dispatch(setContentPopup2(response?.data?.message))
    }

  }
  useEffect(() => {
    // const { location } = window
    if (account) {
      dispatch(fetchInviteRegister(account))
    }
    if (
      location.search !== '' && location.search !== null && 
      // location.pathname === '/staking' && 
      account ) {
      const queryString = location.search
      // console.log('queryString===>', queryString);
      const urlParams = new URLSearchParams(queryString)
      const ref = urlParams.get('ref')
      console.log('ref===>', ref)
      const params = {
        inviter: ref,
        addr: account?.toLocaleLowerCase(),
      }

      // apiInviteMember(params)
      dispatch(fetchInviteMember(params))
    }
  }, [account, location])

  return (
    <>
      <Router history={history}>
        <Notice />
        <Notice2 />
        <GlobalStyle />
        <Loading />
        {/* <Popup /> */}

        <Menu account={account} login={login} logout={logout} errorr={errorr} />
        {errorr ? (
          <Route component={NotFound} />
        ) : (
          <>
            {/* <Route exact path="/">
                <HomePage />
              </Route> */}
            {/* {location?.pathname === "/" && (
                <Redirect exact from="/" to='/staking' />
              )} */}
            <Route exact path="/staking">
              <MixLayout>
                <StakeComponent />
              </MixLayout>
            </Route>
            <Route exact path="/profile">
              <MixLayout>
                <Profile />
              </MixLayout>
            </Route>
            <Route exact path="/">
              <Introduce />
            </Route>

            <Route exact path="/policy">
              <CommissionPolicy />
            </Route>
          </>
        )}
      </Router>
    </>
  )
}

export default React.memo(App)
