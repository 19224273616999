import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
// import useRefresh from 'hooks/useRefresh'
import { State } from './types'

// const ZERO = new BigNumber(0)

export const useFetchPublicData = () => {
  const dispatch = useAppDispatch()
  // const { slowRefresh } = useRefresh()

  useEffect(() => {
    // dispatch(fetchBalance())
    // dispatch(fetchPakageIdInfo())
    // dispatch(fetchPriceKlay())
  }, [dispatch])
}


export const useUserData = () => {
  return useSelector((state: State) => {
    return state.dataUser.data
  })
}


export const useGetPopupData = () => {
  return useSelector((state: State) => {
    return state.popup
  })
}

export const useGetIndicatorData = () => {
  return useSelector((state: State) => {
    return state.indicator
  })
}


export const useGetInviteData = () => {
  return useSelector((state: State) => {
    return state.inviteMember
  })
}



export const useGetUser = () => {
  return useSelector((state: State) => {
    return state.dataUser
  })
}

