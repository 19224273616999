import styled, { css } from 'styled-components'
import color from 'style/color'
import bgr from 'assets/images/bgr.png'

interface Props {
  colorTab?: string
}

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh);
  padding-top: 100px;
  background-image: url(${bgr});
  background-size: cover;
  background-color: #1F1F1F;
  @media (max-width: 767px) {
    /* padding-top: 60px; */
  }
`

// Content
const Content = styled.div`
  /* max-width: 1600px;
  margin: auto;
  height: auto;
  display: block;
  padding: 38px 15px;
  @media screen and (min-width: 1024px) {
    padding: 50px 30px;
  }
  @media screen and (min-width: 720px) and (max-width: 767px) {
    padding: 30px 40px;
  } */
`

const TabMenu = styled.div`
  width: 100%;
  height: 56px;
  border-radius: 28px;
  background-color: ${color.tabNavigation};
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.32);
  margin-bottom: 26px;
  outline: none;

  @media screen and (max-width: 375px) {
    height: 50px;
  }
`

const TabItem = styled.div<Props>`
  min-width: 32%;
  height: 48px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${color.tabNavigationHide};
  font-family: 'GmarketSansTTF-Medium';
  outline: none;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;

  ${({ colorTab }) =>
    colorTab
      ? css`
          background: radial-gradient(rgba(0, 0, 0, 0.32) 50px, ${colorTab});
          box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.48);
        `
      : '#212121'});

  @media screen and (max-width: 375px) {
    height: 30px;
  }
`

export { Wrapper, TabMenu, Content, TabItem }
