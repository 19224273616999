import React, { Suspense } from "react";
import ReactDOM from 'react-dom'
import { Router } from "react-router";
import Loading from "views/Loading";
import { createBrowserHistory } from 'history'
import App from './App'
import Providers from './Providers'
import "antd/dist/antd.css";

const history = createBrowserHistory();


ReactDOM.render(
  <React.StrictMode>
    <Providers>
    <Router history={history}>

      <Suspense fallback={<Loading />}>
        <App />
      </Suspense>
      </Router>

    </Providers>
  </React.StrictMode>,
  document.getElementById('root'),
)
