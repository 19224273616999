import styled from 'styled-components'
import { tags, HeadingProps } from './types'
import Text from '../Text/Text'


const Heading = styled(Text).attrs({ bold: true })<HeadingProps>`
  font-weight: 600;
  line-height: 1.1;
`

Heading.defaultProps = {
  as: tags.H2,
}

export default Heading
