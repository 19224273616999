/* eslint-disable react/no-unused-prop-types */
import React from "react";
import styled from "styled-components";
import Button from "components/Button/Button";
import Text from "components/Text/Text";
import { CheckmarkIcon } from "components/Svg";
import { connectorLocalStorageKey } from "./config";
import { Login, Config } from "./types";

interface Props {
  id: any;
  walletConfig: Config;
  // eslint-disable-next-line react/require-default-props
  login?: Login;
  // eslint-disable-next-line react/require-default-props
  onDismiss?: () => void;
  setWalletSelect: any;
  walletActive: any;
  setWalletActive: any;
  mb: string;
}

const ButtonWallet = styled(Button)`
  height: 62px;
  &:hover {
    border-color: #375bd2 !important;
  };
  @media screen and (min-width: 320px) and (max-width: 720px) {
    height: 50px;
  }
`;

const ActiveCheck = styled.div`
  width: 38px;
  height: 38px;
  flex: 0 0 38px;
  background-image: linear-gradient(0deg, #375bd2  0%, rgb(63 58 255 / 75%) 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  .hideCheck {
    display: none;
  }
`;

const DisableCheck = styled.div`
  width: 38px;
  height: 38px;
`;

const WalletCard: React.FC<Props> = ({
  id,
  walletConfig,
  setWalletSelect,
  walletActive,
  setWalletActive,
  mb,
}) => {
  const { title, icon: Icon } = walletConfig;
  const width = window.innerWidth;
  return (
    <ButtonWallet
      width="100%"
      variant="tertiary"
      onClick={() => {
        // login(walletConfig.connectorId);
        setWalletSelect(walletConfig.connectorId);
        window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId);
        // onDismiss();
        setWalletActive(id);
      }}
      style={{
        justifyContent: "space-between",
        // border: `${active ? "solid 3px #0cd3ff" : "solid 3px #444444"}`,
        border: "solid 3px #444444",
        backgroundColor: "#070707",
        fontSize: "16px",
        padding: width <= 375 ? "0px 15.2px 0px 20px" : "0px 47px",
      }}
      mb={mb}
      id={`wallet-connect-${title.toLocaleLowerCase()}`}
    >
      <Icon width="38px" />
      {/* <div style={{width: '130px', height: '38px'}}>
      <img src={Icon} alt="icon"  style={{maxHeight: '100%'}} />
      </div> */}
      <Text bold color="#ffffff">
        {title}
      </Text>
      {walletActive === id ? (
        <ActiveCheck>
          <CheckmarkIcon style={{ fontWeight: "bold" }} color="#fff" />
        </ActiveCheck>
      ) : (
        <DisableCheck />
      )}
    </ButtonWallet>
  );
};

export default React.memo(WalletCard);
